h3.filter {
    margin-top: 25px;
    font-size: 18px;
    color: #0695d6;
    padding: 10px 15px;
    font-weight: bold;
    background-color: #eee;
    border-radius: 4px;
}
.navbar-default{
    background-color: #37bbed !important;
}
.main-footer li a{
    color: #fff !important;
}
.rojo {
    background-color: #d9534f !important;
}
.botonBasesMobile{
    visibility: hidden !important;


}
.facetapi-facetapi-links .badge {
    line-height: 1.5;
    min-width: 35px;
    border-radius: 4px;
}
aside .panel-body h2.h3.section-title {
    color: #777;
    text-transform: capitalize;
}
.argentina-chosen {
    width: 100%!important;
}
.chosen-container.chosen-container-single .chosen-single {
    margin: -3px 0 0 0!important;
    border: none!important;
    box-shadow: none!important;
}
.chosen-container-single .chosen-drop {
    left: 1px;
}
.chosen-container-active.chosen-with-drop .chosen-single div b {
    background-position: -16px 8px;
}
.chosen-container-single .chosen-single div b {
    background-position: 0px 8px;
}
td.webform-grid-option,
th.webform-grid-option {
    display: table-cell!important
}
.form-group .table-responsive>.table>tbody>tr>td,
.form-group .table-responsive>.table>tbody>tr>th,
.form-group .table-responsive>.table>tfoot>tr>td,
.form-group .table-responsive>.table>tfoot>tr>th,
.form-group .table-responsive>.table>thead>tr>td,
.form-group .table-responsive>.table>thead>tr>th {
    white-space: inherit;
}
.form-group .table-responsive {
    overflow: hidden;
}
.container-inline-date {
    clear: both;
}
.container-inline-date .form-item {
    float: none;
    margin: 0;
    padding: 0;
}
.container-inline-date > .form-item {
    display: inline-block;
    margin-right: 0.5em;
    vertical-align: top;
}
fieldset.date-combo .container-inline-date > .form-item {
    margin-bottom: 10px;
}
.container-inline-date .form-item .form-item {
    float: left;
}
.container-inline-date .form-item,
.container-inline-date .form-item input {
    width: auto;
}
.container-inline-date .description {
    clear: both;
}
.container-inline-date .form-item input,
.container-inline-date .form-item select,
.container-inline-date .form-item option {
    margin-right: 5px;
}
.container-inline-date .date-spacer {
    margin-left: -5px;
}
.views-right-60 .container-inline-date div {
    margin: 0;
    padding: 0;
}
.container-inline-date .date-timezone .form-item {
    clear: both;
    float: none;
    width: auto;
}
.container-inline-date .date-padding {
    float: left;
}
fieldset.date-combo .container-inline-date .date-padding {
    padding: 10px;
}
.views-exposed-form .container-inline-date .date-padding {
    padding: 0;
}
#calendar_div,
#calendar_div td,
#calendar_div th {
    margin: 0;
    padding: 0;
}
#calendar_div,
.calendar_control,
.calendar_links,
.calendar_header,
.calendar {
    border-collapse: separate;
    margin: 0;
    width: 185px;
}
.calendar td {
    padding: 0;
}
span.date-display-single {}span.date-display-start {}span.date-display-end {}.date-prefix-inline {
                                                                                 display: inline-block;
                                                                             }
.date-clear {
    clear: both;
    display: block;
    float: none;
}
.date-no-float {
    clear: both;
    float: none;
    width: 98%;
}
.date-float {
    clear: none;
    float: left;
    width: auto;
}
.date-float .form-type-checkbox {
    padding-right: 1em;
}
.form-type-date-select .form-type-select[class*=hour] {
    margin-left: .75em;
}
.date-container .date-format-delete {
    float: left;
    margin-top: 1.8em;
    margin-left: 1.5em;
}
.date-container .date-format-name {
    float: left;
}
.date-container .date-format-type {
    float: left;
    padding-left: 10px;
}
.date-container .select-container {
    clear: left;
    float: left;
}
div.date-calendar-day {
    background: #F3F3F3;
    border-top: 1px solid #EEE;
    border-left: 1px solid #EEE;
    border-right: 1px solid #BBB;
    border-bottom: 1px solid #BBB;
    color: #999;
    float: left;
    line-height: 1;
    margin: 6px 10px 0 0;
    text-align: center;
    width: 40px;
}
div.date-calendar-day span {
    display: block;
    text-align: center;
}
div.date-calendar-day span.month {
    background-color: #B5BEBE;
    color: white;
    font-size: .9em;
    padding: 2px;
    text-transform: uppercase;
}
div.date-calendar-day span.day {
    font-size: 2em;
    font-weight: bold;
}
div.date-calendar-day span.year {
    font-size: .9em;
    padding: 2px;
}
.date-form-element-content-multiline {
    padding: 10px;
    border: 1px solid #CCC;
}
.form-item.form-item-instance-widget-settings-input-format-custom,
.form-item.form-item-field-settings-enddate-required {
    margin-left: 1.3em;
}
#edit-field-settings-granularity .form-type-checkbox {
    margin-right: .6em;
}
.date-year-range-select {
    margin-right: 1em;
}
#ui-datepicker-div {
    font-size: 100%;
    font-family: Verdana, sans-serif;
    background: #eee;
    border-right: 2px #666 solid;
    border-bottom: 2px #666 solid;
    z-index: 9999;
}
.ui-datepicker {
    width: 17em;
    padding: .2em .2em 0;
}
.ui-datepicker .ui-datepicker-header {
    position: relative;
    padding: .2em 0;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
    position: absolute;
    top: 2px;
    width: 1.8em;
    height: 1.8em;
}
.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
    top: 1px;
}
.ui-datepicker .ui-datepicker-prev {
    left: 2px;
}
.ui-datepicker .ui-datepicker-next {
    right: 2px;
}
.ui-datepicker .ui-datepicker-prev-hover {
    left: 1px;
}
.ui-datepicker .ui-datepicker-next-hover {
    right: 1px;
}
.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -8px;
    top: 50%;
    margin-top: -8px;
}
.ui-datepicker .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center;
}
.ui-datepicker .ui-datepicker-title select {
    float: left;
    font-size: 1em;
    margin: 1px 0;
}
.ui-datepicker select.ui-datepicker-month-year {
    width: 100%;
}
.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
    width: 49%;
}
.ui-datepicker .ui-datepicker-title select.ui-datepicker-year {
    float: right;
}
.ui-datepicker table {
    width: 100%;
    font-size: .9em;
    border-collapse: collapse;
    margin: 0 0 .4em;
}
.ui-datepicker th {
    padding: .7em .3em;
    text-align: center;
    font-weight: bold;
    border: 0;
}
.ui-datepicker td {
    border: 0;
    padding: 1px;
}
.ui-datepicker td span,
.ui-datepicker td a {
    display: block;
    padding: .2em;
    text-align: right;
    text-decoration: none;
}
.ui-datepicker .ui-datepicker-buttonpane {
    background-image: none;
    margin: .7em 0 0 0;
    padding: 0 .2em;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
}
.ui-datepicker .ui-datepicker-buttonpane button {
    float: right;
    margin: .5em .2em .4em;
    cursor: pointer;
    padding: .2em .6em .3em .6em;
    width: auto;
    overflow: visible;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
    float: left;
}
.ui-datepicker.ui-datepicker-multi {
    width: auto;
}
.ui-datepicker-multi .ui-datepicker-group {
    float: left;
}
.ui-datepicker-multi .ui-datepicker-group table {
    width: 95%;
    margin: 0 auto .4em;
}
.ui-datepicker-multi-2 .ui-datepicker-group {
    width: 50%;
}
.ui-datepicker-multi-3 .ui-datepicker-group {
    width: 33.3%;
}
.ui-datepicker-multi-4 .ui-datepicker-group {
    width: 25%;
}
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header {
    border-left-width: 0;
}
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
    border-left-width: 0;
}
.ui-datepicker-multi .ui-datepicker-buttonpane {
    clear: left;
}
.ui-datepicker-row-break {
    clear: both;
    width: 100%;
}
.ui-datepicker-rtl {
    direction: rtl;
}
.ui-datepicker-rtl .ui-datepicker-prev {
    right: 2px;
    left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next {
    left: 2px;
    right: auto;
}
.ui-datepicker-rtl .ui-datepicker-prev:hover {
    right: 1px;
    left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next:hover {
    left: 1px;
    right: auto;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane {
    clear: right;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button {
    float: left;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current {
    float: right;
}
.ui-datepicker-rtl .ui-datepicker-group {
    float: right;
}
.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header {
    border-right-width: 0;
    border-left-width: 1px;
}
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
    border-right-width: 0;
    border-left-width: 1px;
}
.views-row .views-field-field-resumen .field-content {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 25px;
}
.views-row .views-field-title span {
    width: 100%;
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 10px;
}
#block-views-calendario-bloque-block .block-title {
    color: black;
}
#edit-submit-calendario-bloque {
    background-color: #0695d6;
    color: white;
}
#edit-submit-calendario-bloque:active {
    box-shadow: none;
}
#edit-submit-calendario-bloque:focus {
    outline: 0;
}
.view-header {
    position: relative;
}
.view-calendario-bloque .filter-calendario {
    content: '';
    background-image: url(/profiles/argentinagobar/modules/contrib/ev_calendar/img/filter.png);
    background-repeat: no-repeat;
    width: 25px;
    height: 20px;
    position: absolute;
    top: -35px;
    right: 0;
    cursor: pointer;
}
#edit-field-provincia-value,
#edit-type-1 {
    height: 35px;
}
#views-exposed-form-calendario-bloque-block {
    position: absolute;
    right: 0;
    width: 350px;
    padding: 25px;
    background-color: white;
    border: 1px solid darkgray;
    z-index: -1;
}
#views-exposed-form-calendario-bloque-block.active {
    z-index: 9;
}
.view-calendario-bloque .views-exposed-widget.views-submit-button,
.view-calendario-bloque .view-filters {
    position: relative;
    display: flex!important;
    justify-content: flex-end;
}
.view-calendario-bloque .views-exposed-form .views-exposed-widget {
    float: none;
}
.view-calendario-bloque .view-content .views-row {
    width: 50%;
    height: 100px;
    margin-bottom: 20px;
    float: left;
    background-color: #D0EBF4;
    border-radius: 5px;
    overflow-y: auto;
    cursor: pointer;
}
.view-calendario-bloque .view-content .views-row:nth-child(odd) {
    width: 49%;
    margin-right: 1%;
}
.view-calendario-bloque .view-content .views-row .grupo-fecha- {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100px;
    height: 100px;
    margin-right: 20px;
    text-align: center;
    float: left;
    color: white;
    border-radius: 5px 0 0 5px;
}
.view-calendario-bloque .view-content .Capacitaciones .grupo-fecha- {
    background-color: #00B65A;
}
.view-calendario-bloque .view-content .Eventos .grupo-fecha- {
    background-color: #DD1763;
}
.view-calendario-bloque .views-field-field-fecha {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 0;
}
.view-calendario-bloque .views-field-field-fecha-1 {
    margin-top: -5px;
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.view-calendario-bloque .views-field-type {
    text-transform: uppercase;
    font-style: italic;
    padding-top: 10px;
    margin-bottom: 0;
    font-size: 13px;
}
.view-calendario-bloque .views-field-title,
.view-calendario-bloque .views-field-type,
.view-calendario-bloque .views-field-field-resumen,
.view-calendario-bloque .views-field-field-provincia {
    margin-left: 120px;
}
.Eventos .modal-calendario .views-field-type {
    color: #DD1763;
}
.Capacitaciones .modal-calendario .views-field-type {
    color: #00B65A;
}
.view-calendario-bloque .views-field-title span,
.modal-title {
    font-weight: bold;
    color: black;
    font-size: 17px;
    cursor: pointer;
}
.view-calendario-bloque .views-field-field-provincia {
    font-size: 11px;
    font-weight: bold;
}
.view-calendario-bloque .views-field-field-provincia:before {
    content: "";
    background-image: url(/profiles/argentinagobar/modules/contrib/ev_calendar/img/location.png);
    background-size: 12px;
    background-repeat: no-repeat;
    display: inline-block;
    width: 15px;
    height: 20px;
    float: left;
}
.view-calendario .view-filters {
    margin-bottom: 15px;
}
.view-calendario .views-exposed-form {
    background-color: lightgray;
    padding: 10px;
}
.view-calendario .views-exposed-form .views-exposed-widgets {
    margin: 0;
}
.view-calendario .views-exposed-form .views-exposed-widgets #edit-field-provincia-value-wrapper {
    padding: 0;
}
.view-calendario .form-item-field-provincia-value {
    width: 300px;
}
.view-calendario #edit-field-provincia-value-wrapper {
    display: flex;
    align-items: center;
}
.view-calendario #edit-field-provincia-value-wrapper label {
    float: left;
    margin: 0 10px 0;
}
.view-calendario #edit-field-provincia-value-wrapper .views-widget {
    float: left;
}
.view-calendario .fc-corner-left,
.view-calendario .fc-corner-right {
    background-color: #0695d6;
    background-image: none;
    color: white;
}
.view-calendario .fc-header-title {
    color: #0695d6;
    font-size: 12px;
}
.view-calendario .fc-button-today {
    width: 75px;
    text-align: center;
}
.view-calendario .colors-node-type-capacitacion,
.view-calendario .colors-node-type-capacitacion .fc-event-time {
    background-color: #00B65A;
    border-color: #00B65A;
}
.view-calendario .colors-node-type-evento,
.view-calendario .colors-node-type-evento .fc-event-time {
    background-color: #DD1763;
    border-color: #DD1763;
}
.modal-calendario {
    position: absolute;
    top: inherit;
    left: 50%;
    width: 600px;
    height: 250px;
    margin-top: -100px;
    margin-left: -300px;
    cursor: default;
    z-index: 9;
}
.modal-calendario .row {
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
}
.modal-container {
    margin-top: 20px;
}
.row.modal-footer {
    margin-top: 71px;
    box-shadow: none;
    display: flex;
    align-items: center;
}
.modal-color_green {
    padding-top: 25px;
    color: white;
    text-align: center;
}
.Eventos .modal-color_green {
    background-color: #DD1763;
}
.Capacitaciones .modal-color_green {
    background-color: #00B65A;
}
.modal-title a {
    font-weight: bold;
    color: black;
    font-size: 17px;
}
.row.modal-footer .col-md-6 {
    display: flex;
}
.pull-right {
    justify-content: flex-end;
}
.btn.btn-sm {
    margin-bottom: 0;
}
#views-exposed-form-calendario-bloque-block.active {
    background-color: white;
    border: 1px solid darkgray;
    border-radius: 5px;
}
#views-exposed-form-calendario-bloque-block.active div,
#edit-type-1-wrapper.active,
#edit-field-provincia-value-wrapper.active,
.views-field.views-field-nothing,
#edit-submit-calendario-bloque.active {
    display: block;
}
#views-exposed-form-calendario-bloque-block {
    background: transparent;
    border: none;
}
#edit-type-1-wrapper,
#edit-field-provincia-value-wrapper,
#edit-submit-calendario-bloque,
.views-field.views-field-nothing {
    display: none;
}
#modal-calendario.hidden {
    display: none!important;
}
.x {
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 32px;
    height: 32px;
    cursor: pointer;
}
.x:before,
.x:after {
    content: "";
    position: absolute;
    display: block;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 32px;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.5);
    transform-origin: center;
}
.x:before {
    transform: rotate(45deg);
}
.x:after {
    transform: rotate(-45deg);
}
.modal-calendar .x:before,
.modal-calendar .x:after {
    width: 20px;
    border-top: 1px solid white;
}
.modal-calendar {
    display: none;
    position: fixed;
    bottom: 100px;
    left: 50%;
    z-index: 9;
    width: 400px;
    margin-top: -100px;
    margin-left: -200px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
}
.modal-calendar.active {
    display: block;
}
.modal-calendar .row {
    margin: 0;
}
.modal-calendar_header {
    background-color: #00B65A;
    color: white;
    padding: 30px 20px 10px;
}
.modal-calendar_body {
    padding: 30px 20px 10px;
    color: black;
}
.text-u {
    text-transform: uppercase;
    margin: 0;
}
.pull-right .btn {
    margin: 0;
}
.header-description {
    font-size: 15px;
}
.modal-calendar_body p,
.modal-calendar_body a {
    font-size: 15px;
}
.modal-calendar_hora:before {
    content: '';
    width: 20px;
    height: 20px;
    background-image: url(/profiles/argentinagobar/modules/contrib/ev_calendar/img/clock.png);
    background-size: 12px;
    background-position-y: center;
    background-repeat: no-repeat;
    display: inline-block;
    float: left;
}
.modal-calendar_fecha:before {
    content: '';
    width: 20px;
    height: 20px;
    background-image: url(/profiles/argentinagobar/modules/contrib/ev_calendar/img/calendar.png);
    background-size: 12px;
    background-position-y: center;
    background-repeat: no-repeat;
    display: inline-block;
    float: left;
}
.modal-calendar_ubicacion:before {
    content: '';
    width: 20px;
    height: 20px;
    background-image: url(/profiles/argentinagobar/modules/contrib/ev_calendar/img/location.png);
    background-size: 12px;
    background-position-y: center;
    background-repeat: no-repeat;
    display: inline-block;
    float: left;
}
.modal-calendar_descripcion:before {
    content: '';
    width: 20px;
    height: 20px;
    background-image: url(/profiles/argentinagobar/modules/contrib/ev_calendar/img/document.png);
    background-size: 12px;
    background-position-y: center;
    background-repeat: no-repeat;
    display: inline-block;
    float: left;
}
#modal-calendar_descripcion {
    overflow-y: auto;
    height: 100px;
}
.modal-container .views-field-type,
.modal-container .views-field-field-provincia {
    margin-left: 0;
}
.my-2 {
    margin: 5px 0;
}
.fc-agenda-days,
.fc-agenda div div:nth-of-type(3) {
    height: 1115px!important;
}
#modal-calendario .row {
    display: flex;
    flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
    .botonBasesMobile{
        visibility: visible !important;

    }
    .botonBases{
        visibility: hidden !important;
        width: 0 !important;
        display: none;

    }
    .modal-calendario {
        top: 40%;
    }
    #modal-calendario .views-field-field-provincia:before {
        height: 12px;
        float: none;
    }
    #modal-calendario .views-field-title,
    #modal-calendario .views-field-type,
    #modal-calendario .views-field-field-resumen,
    #modal-calendario .views-field-field-provincia {
        margin-left: 0;
    }
    .modal-calendar {
        width: 300px;
        margin-left: -150px;
    }
    .x:before,
    .x:after {
        border-top: 1px solid white!important;
    }
    .modal-calendario {
        width: 300px;
        margin-left: -150px;
    }
    .modal-color_green {
        height: auto;
        padding: 25px 0;
    }
    .modal-container {
        height: 120px;
        text-align: center;
        overflow-y: auto;
    }
    .x {
        top: -95px!important;
    }
    .page-calendario .x {
        top: 10px!important;
    }
    .row.modal-footer {
        justify-content: center;
    }
    .view-calendario-bloque .filter-calendario {
        right: 15px;
        top: 35px;
    }
    .fc-header tr {
        height: 140px;
    }
    td.fc-header-right,
    td.fc-header-left {
        width: 100vw;
        display: block;
        text-align: center;
        margin: 15px 0;
    }
    td.fc-header-center {
        width: 100vw;
        margin-top: 40px;
        position: absolute;
        text-align: center;
    }
    .view-calendario .form-item-field-provincia-value {
        width: auto;
    }
    .view-calendario-bloque .view-content {
        display: block;
        margin-top: 75px;
    }
    .view-calendario-bloque .view-content .views-row {
        width: 100%!important;
        float: none;
    }
    #views-exposed-form-calendario-bloque-block {
        top: 75px;
        right: -3px;
    }
    #block-views-calendario-bloque-block .block-title {
        text-align: center;
    }
}
.loader {
    position: fixed;
    margin: 0 auto;
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, .85);
    top: 0;
    z-index: 9;
}
.circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
}
@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}
@keyframes color {
    100%, 0% {
        stroke: #0695d6;
    }
}
.field .field-label {
    font-weight: bold;
}
.field-label-inline .field-label,
.field-label-inline .field-items {
    float: left;
}
form .field-multiple-table {
    margin: 0;
}
form .field-multiple-table th.field-label {
    padding-left: 0;
}
form .field-multiple-table td.field-multiple-drag {
    width: 30px;
    padding-right: 0;
}
form .field-multiple-table td.field-multiple-drag a.tabledrag-handle {
    padding-right: .5em;
}
form .field-add-more-submit {
    margin: .5em 0 0;
}
div.field-widget-field-hidden.form-wrapper {
    display: none;
}
.node-unpublished {
    background-color: #fff4f4;
}
.preview .node {
    background-color: #ffffea;
}
td.revision-current {
    background: #ffc;
}
.form-item.form-type-select.form-item-user-prune-time-year,
.form-item.form-type-select.form-item-user-prune-time-month,
.form-item.form-type-select.form-item-user-prune-time-day,
.form-item.form-type-select.form-item-user-prune-time-hour {
    padding: 0;
    margin: 0 5px 0 0;
    float: left;
}
.form-item.form-type-select.form-item-user-prune-time-minute {
    padding: 0;
    margin: 0;
}
#user-prune-form select#edit-user-prune-time-month {
    width: 80px;
}
#user-prune-form select.form-select {
    width: 65px;
    height: 20px;
}
input#edit-user-prune-time-unit-multiply {
    height: 14px;
    margin-top: 2px;
}
.views-exposed-form .views-exposed-widget {
    float: left;
    padding: .5em 1em 0 0;
}
.views-exposed-form .views-exposed-widget .form-submit {
    margin-top: 1.6em;
}
.views-exposed-form .form-item,
.views-exposed-form .form-submit {
    margin-top: 0;
    margin-bottom: 0;
}
.views-exposed-form label {
    font-weight: bold;
}
.views-exposed-widgets {
    margin-bottom: .5em;
}
.views-align-left {
    text-align: left;
}
.views-align-right {
    text-align: right;
}
.views-align-center {
    text-align: center;
}
.views-view-grid tbody {
    border-top: none;
}
.view .progress-disabled {
    float: none;
}