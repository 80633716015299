/*CSS PARA LOGO QUE PIDEN DE CONVOCATORIO DE ECONOMIA*/

.pane-page-breadcrumb {
    align-items: center;
}

.logo-particular-convocatoria{
    padding: 0;
}

.logo-particular-convocatoria img {
    max-width: 100%;
    max-height: 100px;
}

.listado-items {
    margin-top: 60px;
}
